import React from 'react';
import { useParams } from "react-router-dom";
import { useDossierBrouillon } from "../../../hooks/useDossierBrouillon";
import { useEffect, useState } from "react";
import { useMapInfo } from "../../../hooks/useMapInfo";
import { useMapUtil } from "../../../hooks/useMapUtil";
import { Card, Row, Col, Button } from "react-bootstrap";
import Swal from "sweetalert2";

function ResponseStep1() {
    /**
     * Hooks
     */
    const routeParams = useParams();
    const { dbToken } = routeParams;
    const { cancelDossierBrouillonByToken } = useDossierBrouillon();
    const { mapInfo, setStoreAddress, setStoreBrouillon } = useMapInfo();
    const { createLoader, deleteLoader } = useMapUtil();

    /**
     * Handlers
     */
    const handleContinue = () => {
        setStoreBrouillon({
            ...mapInfo.brouillon,
            etape: 2
        });
    };

    /**
     * Handlers
     */
    const handleCancel = () => {
        setStoreBrouillon(null);
        cancelDossierBrouillonByToken(dbToken)
            .then(() => {
                Swal.fire({
                    title: 'Merci',
                    text: 'Votre réponse a bien été prise en compte.',
                    icon: 'warning'
                });
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Erreur',
                    text: error.detail,
                    icon: 'error'
                });
            });
    }

    return (
        <div>
            <Card style={{
                width: '100%',
                maxWidth: '500px',
                marginLeft: '5px',
                marginRight: '5px',
            }}>
                <Card.Body>
                    <Row>
                        <Col className="d-flex align-items-center justify-content-center flex-column">
                            {mapInfo.parcelles?.length == 0 ? (
                                <p className="text-center text-danger">Veuillez cliquer sur les parcelles de votre bien à la vente {mapInfo.brouillon.mandatNumero?<strong>ref. {mapInfo.brouillon.mandatNumero} </strong>:''}pour finaliser la mise en ligne sur notre portail.<br /><small>Aidez-vous de la barre d'adresse en haut de l'écran pour retrouver votre bien.</small></p>
                            ) : (
                                <p className="text-center ">Veuillez cliquer sur les parcelles de votre bien à la vente {mapInfo.brouillon.mandatNumero?<strong>ref. {mapInfo.brouillon.mandatNumero} </strong>:''}pour finaliser la mise en ligne sur notre portail.</p>
                            )}
                            
                            <div className="d-flex justify-content-center">
                                <Button variant="danger" onClick={handleCancel}>Ne pas déposer</Button>
                                <Button variant="success" style={{ marginLeft: 10 }} disabled={mapInfo.parcelles?.length == 0} onClick={handleContinue}>Déposer le bien</Button>
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    );
}

export default ResponseStep1;
